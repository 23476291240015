import React from 'react'
import {NavigationButtonHelper} from 'lib/Helpers/NavigationButton';
import ParallaxBackground from '../../Widgets/ParallaxBackground';


class NewAboutPage extends React.Component {

    componentDidMount () {
        NavigationButtonHelper.showBackButton();
    }

	render() {
        const { parallaxConfiguration, content } = this.props;
        const headerText = content.header
        const paragraphText = content.paragraph
		return (
			<div className="page-newabout">
                <ParallaxBackground parallaxConfiguration={parallaxConfiguration}/>
                <div className="dark-overlay"></div>
                <div className="content">
                    {/* <h1>{headerText}</h1>
                    <div>there is text here</div> */}
                    <p1>
                    {paragraphText}
                        </p1>
                </div>
                <div className='overlay'>
                    
                </div>
                {/* <div className='subject-page-button'>About</div> */}
                <div className="fadeout"></div>
			</div>
		);
	}
}

export default NewAboutPage